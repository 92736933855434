<template>
  <div>
    <b-row>   
      <b-col lg="7">
        <b-row>   
          <b-col lg="5" class="pr-0">
            <b-form-group label="Título">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.config.fieldsTMP.title"
                            placeholder="Título del campo">
              </b-form-input>
            </b-form-group>
          </b-col>                          
          <b-col lg="4" class="pr-0">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.config.fieldsTMP.name"
                            placeholder="Nombre del campo">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="3" class="pr-0">
            <b-form-group label="Tipo">
              <b-form-select v-model="crud.config.fieldsTMP.type" :options="arr.typeFields" size="sm"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4">   
        <b-row>
          <b-col lg="6" class="pr-0">
            <b-form-group label="Columna">
              <b-form-select v-model="crud.config.fieldsTMP.column" :options="crud.config.columns" size="sm"></b-form-select>              
            </b-form-group>
          </b-col>  
          <b-col lg="6" class="pr-0">
            <b-form-group label="Grid">
              <b-form-select v-model="crud.config.fieldsTMP.cols" :options="arr.cols" size="sm"></b-form-select>              
            </b-form-group>
          </b-col>                   
        </b-row>
      </b-col>        
      <b-col lg="1" class="forms-config-fields">
        <b-button type="button" 
                  variant="outline-dark" 
                  class="pull-right" 
                  size="sm" 
                  @click="addField()"
                  v-b-tooltip.hover
                  title="Guardar Campo">                            
          <i v-if="crud.config.fieldsTMP.edit" class="fa fa-save"></i>
          <i v-else class="fa fa-plus"></i>
        </b-button>             
      </b-col>
      <b-col lg="12" v-if="crud.config.fieldsTMP.type=='select'">
        <b-form-input type="text"
                      size="sm"
                      v-model="crud.config.fieldsTMP.options"
                      placeholder="Opciones separadas por coma (,)">
        </b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col lg="12">
        
        <b-list-group v-if="crud.config.fields.length" 
                      class="forms-config-fields-details">         
          <draggable :list="crud.config.fields">
            <b-list-group-item href="#"
                              class="flex-column align-items-start"                                                                      
                              v-for="(value, key) in crud.config.fields"
                              :key="key"
                              v-b-tooltip.hover
                              :disabled="crud.config.fieldsTMP.edit">

              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{value.name}}</h5>
                <small>
                  <b-badge variant="secondary">{{value.type}}</b-badge>
                </small>
              </div>
              
              <b-button @click="deleteField(value)" 
                        type="button" 
                        variant="outline-danger" 
                        size="sm" 
                        class="btn-pill float-right" 
                        v-b-tooltip.hover.bottom="'Quitar Campo'">
                <i class="fa fa-trash"></i>
              </b-button>
              <b-button @click="editField(key)" 
                        type="button" 
                        variant="outline-info" 
                        size="sm" 
                        class="btn-pill float-right mr-1" 
                        v-b-tooltip.hover.bottom="'Editar Campo'">
                <i class="fa fa-edit"></i>                    
              </b-button>     

              <p class="mb-0">                  
                <b>Título:</b> {{value.title}} | <b>Columna:</b> <span v-if="value.column">{{value.column}}</span><span v-else>1</span> | <b>Grid:</b> col-md-{{value.cols}}
              </p>
              <p class="mb-0" v-if="value.options">                  
                  <b>Opciones:</b> 
                <b-badge v-for="(element,key) in value.options.split(',')" 
                        :key="key"
                        variant="secondary"
                        class="ml-1">
                  {{element}}
                </b-badge>
              </p>                  

            </b-list-group-item>  
          </draggable>            
        </b-list-group>
  
      </b-col>
    </b-row>   
  </div>
</template>

<script>    
  import draggable from 'vuedraggable'
 
  export default {
    props: {
      fields: {
        type: Array,
        required: true
      },
      columns: {
        type: Array,
        required: true
      },  
      fieldsAvailables: {
        type: String,
        default: 'string,date,email,text,integer,decimal,checkbox,select,file,hidden'
      }    
    },    
    components: {
      draggable,
    },
    data: () => {
      return {          
        crud: {
          config: {
            fields: [],
            column: [],         
            fieldsTMP: {
              type: 'string',
              name: '',
              cols: 12,
              column: 1,
              options: '',
              title: '',
              index: 0,
              edit: false
            }            
          }    
        },
        arr: {
          typeFields: [],
          cols: [
            {value: 1, text: 'Cols 1'},
            {value: 2, text: 'Cols 2'},
            {value: 3, text: 'Cols 3'},
            {value: 4, text: 'Cols 4'},
            {value: 5, text: 'Cols 5'},
            {value: 6, text: 'Cols 6'},
            {value: 7, text: 'Cols 7'},
            {value: 8, text: 'Cols 8'},
            {value: 9, text: 'Cols 9'},
            {value: 10, text: 'Cols 10'},
            {value: 11, text: 'Cols 11'},
            {value: 12, text: 'Cols 12'},
          ],          
        }     
      }
    },
    mounted() {      
      this.crud.config.fields = this.fields
      this.crud.config.columns = this.columns

      if(this.fieldsAvailables) {
        var fieldsAvailables = this.fieldsAvailables.split(',')
        fieldsAvailables.forEach(element => {
          if(element == 'string')
            this.arr.typeFields.push({value: 'string', text: 'Texto'})

          if(element == 'date')
            this.arr.typeFields.push({value: 'date', text: 'Fecha'})
            
          if(element == 'email')
            this.arr.typeFields.push({value: 'email', text: 'Email'})
            
          if(element == 'text')
            this.arr.typeFields.push({value: 'text', text: 'Texto Largo'})            

          if(element == 'integer')
            this.arr.typeFields.push({value: 'integer', text: 'Entero'})                        
            
          if(element == 'decimal')
            this.arr.typeFields.push({value: 'decimal', text: 'Decimal'})

          if(element == 'checkbox')
            this.arr.typeFields.push({value: 'checkbox', text: 'Si/No'})
            
          if(element == 'select')
            this.arr.typeFields.push({value: 'select', text: 'Opciones'})

          if(element == 'file')
            this.arr.typeFields.push({value: 'file', text: 'Archivo'})            

          if(element == 'hidden')
            this.arr.typeFields.push({value: 'hidden', text: 'Oculto'})     

          if(element == 'image')
            this.arr.typeFields.push({value: 'image', text: 'Imagen'})    

          if(element == 'forms')
            this.arr.typeFields.push({value: 'forms', text: 'Formulario'})    

          if(element == 'instagram')
            this.arr.typeFields.push({value: 'instagram', text: 'Instagram'})                
        });
      }
    },
    methods: {      
      addField() {
        var item = this.crud.config.fieldsTMP
        var index = this.crud.config.fieldsTMP.index
        var edit = this.crud.config.fieldsTMP.edit

        if(!item.name) {
          this.$awn.alert("No se puede agregar un campo vacío")
          return false
        }

        var valid = false
        this.crud.config.fields.forEach((element, key) => {          
          if(edit) {             
            // EDITAR
            if(element.name==item.name && key!=index) {
              valid = true
            }            
          } else {
            // AGREGAR
            if(element.name==item.name) {              
              valid = true
            }                        
          }
        });

        if(valid) {
          this.$awn.alert("No se puede agregar dos veces el mismo campo")
          this.clearFieldTMP()
          return false
        }

        if(edit) {          
          // EDITAR
          this.crud.config.fields[index] = {
            type: item.type,
            name: item.name,            
            cols: item.cols,
            column: item.column,
            options: item.options,    
            title: item.title,    
          }
          this.clearFieldTMP()
        } else {
          // AGREGAR
          this.crud.config.fields.push({
            type: item.type,
            name: item.name,            
            cols: item.cols,
            column: item.column,
            options: item.options,    
            title: item.title,    
          })
          this.clearFieldTMP()          
        }   
        
        this.$emit('fields-config', this.crud.config.fields)    
      },
      editField(index) {        
        this.crud.config.fieldsTMP.type = this.crud.config.fields[index].type
        this.crud.config.fieldsTMP.name = this.crud.config.fields[index].name
        this.crud.config.fieldsTMP.cols = this.crud.config.fields[index].cols
        this.crud.config.fieldsTMP.column = this.crud.config.fields[index].column
        this.crud.config.fieldsTMP.options = this.crud.config.fields[index].options
        this.crud.config.fieldsTMP.title = this.crud.config.fields[index].title

        this.crud.config.fieldsTMP.index = index
        this.crud.config.fieldsTMP.edit = true
      },
      deleteField(item) {        
        var i = this.crud.config.fields.indexOf( item );

        if ( i !== -1 ) {
          this.crud.config.fields.splice( i, 1 );
        }        

        this.$emit('fields-config', this.crud.config.fields)    
      },
      clearFieldTMP() {
        this.crud.config.fieldsTMP.type = 'string'
        this.crud.config.fieldsTMP.name = ''
        this.crud.config.fieldsTMP.cols = 12
        this.crud.config.fieldsTMP.column = 1
        this.crud.config.fieldsTMP.options = ''
        this.crud.config.fieldsTMP.title = ''
        this.crud.config.fieldsTMP.index = 0
        this.crud.config.fieldsTMP.edit = false
      }
    }    
  }
</script>
<style>
  .forms-config-fields {
    margin-top: 30px;
  }
  .forms-config-fields-details{
    overflow: overlay;
    max-height: 350px;
  }
</style>